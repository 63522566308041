import React from "react"
import Img from "gatsby-image"
import {Button} from "@material-ui/core"
import {useStaticQuery} from 'gatsby'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

import LayoutStyle from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "kasko_mascot2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return(
    <LayoutStyle>
      <SEO title="Innhold ikke funnet :/" />

      <Jumbotron>
        <Container style={{textAlign:'center'}}>
          <h1>Jeg fant ikke dette innholdet :/</h1>
          <div style={{maxWidth: '500px'}}><Img fluid={data.file.childImageSharp.fluid}/></div>
        </Container>
      </Jumbotron>

    </LayoutStyle>
  )
}

export default NotFoundPage
